<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import { toast } from "vue3-toastify";

export default {
  data() {
    return {
      cantidadDestinatarios: 0,
      inputs: [],
      nuevoDestinatario: {
        data: "",
        errorMessage: "",
      },
      errors: [],
      destinatariosConsultados: [],
      formSubmitted: false,
      agregarDestinatario: false,
    };
  },
  async mounted() {
    try {
      await this.consultarDestinatarios();
    } catch (error) {
      this.$swal({
        icon: error.msg ? "error" : "warning",
        title: error.code ? error.code : "Error",
        text: error.msg ? error.msg : null,
      });
    }
  },
  computed: {
    ...mapState("destinatarios", ["destinatarios"]),
  },
  watch: {
    destinatarios(nuevosDestinatarios, viejosDestinatarios) {
      this.destinatariosConsultados = nuevosDestinatarios;
      console.log(this.destinatariosConsultados);
      this.destinatariosConsultados.forEach((destinatario) => {
        this.inputs[destinatario.id] = {
          data: destinatario.email,
          errorMessage: "",
        };
      });
      this.cantidadDestinatarios = this.destinatariosConsultados.length;
      if(this.cantidadDestinatarios == 0){
        this.agregarDestinatario = true;
      }
    },
  },
  methods: {
    ...mapActions("destinatarios", [
      "guardarDestinatario",
      "consultarDestinatarios",
      "borrarDestinatario",
    ]),
    agregarFormularioDestinatario() {
      this.agregarDestinatario = !this.agregarDestinatario;
    },
    validarCorreo(idDestinatario) {
      this.errors = [];
      const emailRegex =
        /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;

      this.formSubmitted = true;
      let values = this.inputs;

      console.log(values);
      console.log(idDestinatario);
      console.log(values[idDestinatario]);
      console.log(values[idDestinatario].data);

      !emailRegex.test(values[idDestinatario].data)
        ? (values[idDestinatario].errorMessage = "Correo inválido")
        : (values[idDestinatario].errorMessage = "");

      if (values[idDestinatario].errorMessage) {
        this.errors.push(values[idDestinatario].errorMessage);
      }

      console.log(this.errors);
      if (this.errors.length == 0) {
        console.log("guardando");
        this.guardarActualizarDestinatario(
          values[idDestinatario].data,
          idDestinatario
        );
      }
    },
    validarNuevoDestinatario() {
      this.errors = [];
      const emailRegex =
        /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$/;

      this.formSubmitted = true;
      let values = this.inputs;

      !emailRegex.test(this.nuevoDestinatario.data)
        ? (this.nuevoDestinatario.errorMessage = "Correo inválido")
        : (this.nuevoDestinatario.errorMessage = "");

      if (this.nuevoDestinatario.errorMessage) {
        this.errors.push(this.nuevoDestinatario.errorMessage);
      }

      console.log(this.errors);
      if (this.errors.length == 0) {
        console.log("guardando");
        this.guardarActualizarDestinatario(this.nuevoDestinatario.data);
      }
    },
    async guardarActualizarDestinatario(correo, id) {
      try {
        await this.guardarDestinatario({ correo: correo, id: id ? id : null });
        this.agregarDestinatario = false;
        this.nuevoDestinatario.data = "";
        this.nuevoDestinatario.errorMessage = "";

        toast.success(
          id ? " !Destinatario Actualizado! " : " !Destinatario Creado! ",
          {
            hideProgressBar: true,
            autoClose: true,
            theme: "colored",
          }
        );
      } catch (error) {
        this.$swal({
          icon: error.msg ? "error" : "warning",
          title: error.code ? error.code : "Error",
          text: error.msg ? error.msg : null,
        });
      }
    },
    async eliminarDestinatario(idDestinatario) {
      try {
        await this.borrarDestinatario(idDestinatario);
        toast.success(" !Destinatario Eliminado! ", {
          hideProgressBar: true,
          autoClose: true,
          theme: "colored",
        });
      } catch (error) {
        this.$swal({
          icon: error.msg ? "error" : "warning",
          title: error.code ? error.code : "Error",
          text: error.msg ? error.msg : null,
        });
      }
    },
  },
};
</script>

<template>
  <Breadcrumbs title="Reenvío de correos" />
  <div class="card">
    <div class="card-body">
      <div v-for="(destinatario, index) in destinatariosConsultados">
        <form
          class="row form theme-form needs-validation"
          @submit.prevent="validarCorreo(destinatario.id)"
        >
          <div class="mb-3 col-lg-10 col-md-12">
            <label class="form-label text-table-primary" for="correo"
              >Destinatario {{ index + 1 }}</label
            >
            <input
              class="form-control"
              id="correo"
              type="text"
              placeholder="Introduce un correo electrónico"
              v-model="inputs[destinatario.id].data"
              v-bind:class="
                formSubmitted
                  ? inputs[destinatario.id].errorMessage.length
                    ? 'is-invalid'
                    : ''
                  : ''
              "
            />
            <div
              class="invalid-feedback"
              v-if="inputs[destinatario.id].errorMessage.length"
            >
              {{ inputs[destinatario.id].errorMessage }}
            </div>
          </div>
          <div
            class="mb-3 col-lg-2 col-md-12 d-grid gap-1 d-lg-flex justify-content-lg-around align-items-lg-end"
          >
            <button
              class="btn btn-primary d-flex align-items-center justify-content-center text-white px-3"
              type="submit"
            >
              <vue-feather type="upload-cloud" />
            </button>
            <button
              class="btn btn-danger d-flex align-items-center justify-content-center text-white px-3"
              type="button"
              @click="eliminarDestinatario(destinatario.id)"
            >
              <vue-feather type="trash-2" />
            </button>
          </div>
        </form>
      </div>
      <form
        v-if="agregarDestinatario"
        class="row form theme-form needs-validation"
        @submit.prevent="validarNuevoDestinatario()"
      >
        <div class="mb-3 col-lg-10 col-md-12">
          <label class="form-label" for="correo"
            >Destinatario {{ cantidadDestinatarios + 1 }}</label
          >
          <input
            class="form-control"
            id="correo"
            type="text"
            placeholder="Introduce un correo electrónico"
            v-model="this.nuevoDestinatario.data"
            v-bind:class="
              formSubmitted
                ? this.nuevoDestinatario.errorMessage.length
                  ? 'is-invalid'
                  : ''
                : ''
            "
          />
          <div
            class="invalid-feedback"
            v-if="this.nuevoDestinatario.errorMessage.length"
          >
            {{ this.nuevoDestinatario.errorMessage }}
          </div>
        </div>
        <div
          class="mb-3 col-lg-2 col-md-12 d-grid gap-1 d-lg-flex justify-content-lg-center align-items-lg-end"
        >
          <button
            class="btn btn-primary d-flex align-items-center justify-content-center px-4"
            type="submit"
          >
            <vue-feather type="upload-cloud" />
          </button>
        </div>
      </form>
      <div class="row">
        <div class="col d-flex justify-content-center mt-3 text-white">
          <button
            :class="[
              !agregarDestinatario
                ? 'btn btn-primary d-flex align-items-center'
                : 'btn btn-danger d-flex align-items-center',
            ]"
            type="button"
            @click="agregarFormularioDestinatario()"
          >
            <span v-if="!agregarDestinatario" class="me-2">
              Agregar nuevo destinatario
            </span>
            <span v-else class="me-2"> Ocultar formulario</span>
            <vue-feather type="mail" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
