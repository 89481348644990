<template>
    <div class="col-sm-12">
        <div class="card">
            <v-card class="card-header">
                <!-- Tabs o pestañas -->
                <v-tabs v-model="activeTab" align-tabs="start" color="deep-purple-accent-4">
                    <v-tab class="text-table-primary">Búsqueda por filtros</v-tab>
                    <v-tab class="text-table-primary">Búsqueda por Excel</v-tab>
                </v-tabs>

                <!-- Configuracion de lo que contiene cada Tabs -->
                <v-tabs-items v-model="activeTab">
                    <!-- Primera pestaña: Busqueda por Producto -->
                    <v-tab-item>
                        <template v-if="activeTab === 0">
                            <div class="card-header pb-0">
                                <div class="row">
                                    <div class="col-lg-4 col-12 mb-3">
                                        <label class="text-table-primary">Filtrado Por Producto:</label>
                                        <input class="form-control" v-model="filtros.producto" type="text"
                                            placeholder="Producto" @change="consultarProductos" />
                                    </div>
                                    <div class="col-lg-4 col-12 mb-3">
                                        <label class="text-table-primary">Filtrado Por Fecha:</label>
                                        <datepicker class="datepicker-here digits" range id="fechaPedidos"
                                            :enable-time-picker="false" type="date" locale="es" format="dd/MM/yyyy"
                                            select-text="Confirmar" cancel-text="Cancelar" v-model="filtros.fecha"
                                            @selected="handleFechaSeleccionada" />
                                    </div>
                                    <div class="col-lg-4 col-12 mb-3">
                                        <div class="desig-but">
                                            <button @click="consultarProductos" class="btn btn-primary mb-3"
                                                style="margin-right: 10px;">Consultar</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12 mb-3">
                                        <label class="text-table-primary">Filtrado Por Resolución:</label>
                                        <select class="form-select digits" v-model="filtros.resolucion">
                                            <option value="">Todas</option>
                                            <option value="red128">128X128</option>
                                            <option value="red256">256X256</option>
                                            <option value="red512">512X512</option>
                                            <option value="red1024">1024X1024</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 col-12 mb-3">
                                        <label class="text-table-primary">Redimensionada:</label>
                                        <select class="form-select digits" v-model="filtros.redimensionada">
                                            <option value="">Ambas</option>
                                            <option value="1">SI</option>
                                            <option value="0">NO</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-4 col-12 mb-3">
                                        <div class="desig-but">
                                            <button @click="descargarInforme" class="btn btn-primary mb-3">Descargar
                                                Informe</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </v-tab-item>

                    <!-- Segunda pestaña: Busqueda por Excel -->
                    <v-tab-item>
                        <template v-if="activeTab === 1">
                            <div class="card-header pb-0">
                                <div class="row">
                                    <div class="col-lg-4 col-12 mb-3">
                                        <label class="form-label text-table-primary">Cargar Excel:</label>
                                        <div class="input-group">
                                            <input type="file" @change="cargarExcel" accept=".xlsx, .xls"
                                                class="form-control" id="inputGroupFile" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
            <Loader v-if="isLoading" :textoLoader="textoLoader" :tituloLoader="tituloLoader" />
            <div class="card-body">
                <div class="table-responsive theme-scrollbar">
                    <table class="display table-striped table-hover table-bordered table text-table-primary">
                        <thead>
                            <tr>
                                <th>Producto</th>
                                <th>Imagen</th>
                                <th>Fecha</th>
                                <th>RED128</th>
                                <th>RED256</th>
                                <th>RED512</th>
                                <th>RED1024</th>
                                <th>IMG128</th>
                                <th>IMG256</th>
                                <th>IMG512</th>
                                <th>IMG1024</th>
                                <th style="display: flex; justify-content: center">ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="producto in paginatedProductos" :key="producto.id">
                                <td>{{ producto.producto }}</td>
                                <td>
                                    <img :src="producto.url" alt="Imagen del producto"
                                        style="max-width: 100px; max-height: 100px;">
                                </td>

                                <td>{{ formatoFecha(producto.fecha) }}</td>
                                <td>{{ producto.red128 ? 'Disponible' : 'No Redimensionado' }}</td>
                                <td>{{ producto.red256 ? 'Disponible' : 'No Redimensionado' }}</td>
                                <td>{{ producto.red512 ? 'Disponible' : 'No Redimensionado' }}</td>
                                <td>{{ producto.red1024 ? 'Disponible' : 'No Redimensionado' }}</td>
                                <td><img :src="producto.url128" alt="No Redimensionado"
                                        style="max-width: 100px; max-height: 100px;"></td>
                                <td><img :src="producto.url256" alt="No Redimensionado"
                                        style="max-width: 100px; max-height: 100px;"></td>
                                <td><img :src="producto.url512" alt="No Redimensionado"
                                        style="max-width: 100px; max-height: 100px;"></td>
                                <td><img :src="producto.url1024" alt="No Redimensionado"
                                        style="max-width: 100px; max-height: 100px;"></td>

                                <td
                                    style="display: flex; justify-content: center; align-items: center; height: 117px; width: 300px;">
                                    <button @click="mostrarAlertaDetallesProducto(producto)"
                                        class="btn btn-success btn-xs me-2 text-white" type="button" title="Actualizar">
                                        <vue-feather type="edit" />
                                        Actualizar
                                    </button>
                                    <button @click="mostrarOpcionesDescarga(producto)"
                                        class="btn btn-primary btn-xs me-2 text-white" type="button" title="Descargar">
                                        <vue-feather type="save" />
                                        Descargar
                                    </button>
                                    <button @click="confirmarRemoverProducto(producto)"
                                        class="btn btn-danger btn-xs me-2 text-white" type="button" title="Remover">
                                        <vue-feather type="trash-2" />
                                        Remover
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pagination pagination-primary p-2 d-flex justify-content-end align-items-center mt-3">
                        <button class="btn btn-primary btn-xs me-3" @click="previousPage" :disabled="currentPage === 1">
                            Anterior
                        </button>
                        <span class="mx-3">Página {{ currentPage }} de {{ totalPages }}</span>
                        <button class="btn btn-primary btn-xs ms-3" @click="nextPage"
                            :disabled="currentPage === totalPages">
                            Siguiente
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import * as XLSX from "xlsx";
import axios from 'axios';
import Swal from "sweetalert2";
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { sweetAlertMixin } from "../mixins/sweetAlertMixin.js";
import { removeProduct, getProducts, updateProduct } from '@/store/apibase';
import Loader from "@/components/Loader.vue";

export default {
    components: {
        Loader,
    },
    mixins: [sweetAlertMixin],
    data() {
        return {
            productosEncontrados: [],
            cbColumn: [],
            productosExcel: [],
            activeTab: 0,
            productos: [],
            images: [],
            filtros: {
                resolucion: '',
                redimensionada: '',
                producto: '',
                fecha: [new Date(new Date().getFullYear(), new Date().getMonth(), 1), new Date()]
            },
            currentPage: 1,
            itemsPerPage: 20,
            locale: 'es',
            textoLoader:
                "Por favor espere se están cargando los datos",
            tituloLoader: "Cargando Reporte",
            isLoading: false,
        };
    },
    computed: {
        ...mapState("autenticacion", ["usuarioActual"]),
        paginatedProductos() {
            if (this.productos.data) {
                const start = (this.currentPage - 1) * this.itemsPerPage;
                const end = start + this.itemsPerPage;
                return this.productos.data.slice(start, end);
            } else {
                return [];
            }
        },
        totalPages() {
            if (this.productos.data) {
                return Math.ceil(this.productos.data.length / this.itemsPerPage);
            } else {
                return 0;
            }
        }

    },
    methods: {
        ...mapActions("autenticacion", ["cerrarSesionUsuario"]),
        async cargarExcel(event) {
            const file = event.target.files[0];

            if (!file) {
                this.isLoading = false;
                return;
            }

            this.isLoading = true;
            this.tituloLoader = "GENERANDO REPORTE";
            this.textoLoader = "Por favor espere se están cargando los datos";

            const reader = new FileReader();
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                // Obtener la primera hoja del archivo Excel
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];

                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                this.cbColumn = jsonData.map(row => row.cb);

                this.getProducts();
            };
            reader.readAsArrayBuffer(file);
        },
        async getProducts() {
            try {
                const response = await axios.get(getProducts);
                this.productosExcel = response.data.data;

                this.compareCBWithProducts();
            } catch (error) {
                console.error('Error al obtener datos:', error);
                this.isLoading = false;
            }
        },
        compareCBWithProducts() {
            try {
                const productosEncontrados = this.productosExcel.filter(producto =>
                    this.cbColumn.some(cb =>
                        cb && producto.producto &&
                        cb.toString().trim() === producto.producto.toString().trim()
                    )
                );

                if (productosEncontrados.length > 0) {

                    this.exportarExcel(productosEncontrados);
                } else {
                    this.$swal({
                        title: "Sin coincidencias",
                        text: "No se encontraron coincidencias entre los CB del archivo Excel y los productos.",
                        icon: "warning",
                        confirmButtonText: "Aceptar"
                    });
                    this.isLoading = false;
                }
            } catch (error) {
                console.error('Error durante la comparación de productos:', error);
            }
        },
        async exportarExcel() {
            try {
                const datos = this.cbColumn.map(cbFromExcel => {
                    const productoEncontrado = this.productosExcel.find(producto => producto.producto.toString().trim() === cbFromExcel.toString().trim());

                    const fotoDisponibilidad = productoEncontrado ? 'SI' : 'NO';
                    const url128 = productoEncontrado ? productoEncontrado.url128 : '';
                    const url256 = productoEncontrado ? productoEncontrado.url256 : '';
                    const url512 = productoEncontrado ? productoEncontrado.url512 : '';
                    const url1024 = productoEncontrado ? productoEncontrado.url1024 : '';

                    return {
                        cb: cbFromExcel.toString().trim(),
                        foto: fotoDisponibilidad,
                        URL128: url128,
                        URL256: url256,
                        URL512: url512,
                        URL1024: url1024
                    };
                });

                const worksheet = XLSX.utils.json_to_sheet(datos);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Reporte de Productos');
                const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

                const hoy = new Date();
                const dia = String(hoy.getDate()).padStart(2, '0');
                const mes = String(hoy.getMonth() + 1).padStart(2, '0');
                const anio = hoy.getFullYear();

                // Formato de fecha: dd-mm-yyyy
                const fechaHoy = `${dia}-${mes}-${anio}`;

                // Crear un enlace para descargar el archivo
                const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `Reporte banco de imágenes Tiendas Garcés-${fechaHoy}.xlsx`;
                link.click();

                // Mostrar SweetAlert de éxito
                this.$swal({
                    title: "Reporte Generado correctamente",
                    icon: "success",
                    confirmButtonText: "Aceptar"
                });

            } catch (error) {
                console.error('Error al generar el archivo Excel:', error);

                // Mostrar SweetAlert de error
                this.$swal({
                    title: "Error",
                    text: "Hubo un problema al generar el archivo Excel. Por favor, inténtalo de nuevo.",
                    icon: "error",
                    confirmButtonText: "Aceptar"
                });
            } finally {
                this.isLoading = false;
            }
        },
        handleFechaSeleccionada(date) {
            this.filtros.fecha = date;
        },
        consultarProductos() {
            this.isLoading = true;
            this.tituloLoader = "CARGANDO DATOS";
            this.textoLoader = "Por favor espere se están cargando los datos";
            let params = { ...this.filtros };

            if (this.filtros.fecha) {
                const fechaInicio = this.filtros.fecha[0].toISOString(); // Convertir a formato ISO
                const fechaFin = this.filtros.fecha[1].toISOString();

                params.startDate = fechaInicio;
                params.endDate = fechaFin;
            }

            axios.get(getProducts, { params })
                .then(response => {
                    this.productos = response.data;
                    this.currentPage = 1;
                    this.$swal({
                        title: "Consulta Exitosa",
                        html: `
                    <div class="mb-3">
                        <p>Se han encontrado ${response.data.data.length} productos.</p>
                    </div>
                `,
                        icon: "success",
                        confirmButtonText: "Aceptar"
                    });
                })
                .catch(error => {
                    console.error('Error al obtener datos:', error);
                    // Mostrar SweetAlert en caso de error
                    this.$swal({
                        title: "Error en la Consulta",
                        text: "No se pudo obtener los productos. Por favor, intenta nuevamente.",
                        icon: "error",
                        confirmButtonText: "Aceptar"
                    });
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        formatoFecha(fecha) {
            if (!fecha) {
                return "Fecha no disponible";
            }
            const fechaParseada = Date.parse(fecha.replace(' ', 'T'));
            if (!isNaN(fechaParseada)) {
                return new Date(fechaParseada).toLocaleDateString();
            } else {
                return "Fecha no válida";
            }
        },
        descargarInforme() {
            //esta funcion nos ayuda para poder descargar nuestro reporte en formato csv 
            const tableData = this.productos.data.map(producto => ({
                Cb: producto.producto,
                UserId: producto.userId,
                Fecha: this.formatoFecha(producto.fecha),
                RED128: producto.red128 ? 'Disponible' : 'No Redimensionado',
                RED256: producto.red256 ? 'Disponible' : 'No Redimensionado',
                RED512: producto.red512 ? 'Disponible' : 'No Redimensionado',
                RED1024: producto.red1024 ? 'Disponible' : 'No Redimensionado',
                'URL 128': producto.url128,
                'URL 256': producto.url256,
                'URL 512': producto.url512,
                'URL 1024': producto.url1024
            }));
            this.$exportTableToExcel(tableData, 'InformeProductos.xlsx');
        },
        confirmarRemoverProducto(producto) {
            // la funcion que nos muestra un sweetalert para confirmar la eliminacion de un producto si confirmamos nos ejecuta la funcion removerProducto
            this.showAlert(
                "¿Estás seguro de ELIMINAR este producto?",
                "",
                "",
                "Sí, remover",
                "Cancelar",
                producto.url
            ).then((result) => {
                if (result.isConfirmed) {
                    this.removerProducto(producto);
                } else {
                }
            });
        },
        removerProducto(producto) {
            // la funcion para eliminar un producto de la base de datos tanto de mariaDB como de Hasura
            axios.delete(`${removeProduct}${producto.producto}`)
                .then(response => {
                    //console.log("Producto eliminado correctamente");
                    //console.log("Registro eliminado de Hasura correctamente");
                    this.consultarProductos();

                })
                .catch(error => {
                    console.error('Error al eliminar el producto:', error);
                });
        },
        mostrarOpcionesDescarga(producto) {
            // la funcion que nos muestra un sweetalert para los tipos de descarga de cada imagen 
            Swal.fire({
                title: 'Selecciona una opción de descarga',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Descargar Original',
                cancelButtonText: 'Descargar Original y Redimensiones',
                confirmButtonClass: 'mb-3 btn btn-primary text-white',
                cancelButtonClass: 'btn btn-primary text-white',
                buttonsStyling: false,

            }).then((result) => {
                if (result.isConfirmed) {
                    this.descargarImagen(producto);
                } else {
                    this.descargarPaquete(producto);
                }
            });
        },
        descargarImagen(producto) {
            //funcion para descargar una imagen original en su tamaño original
            const url = producto.url;
            const nombreArchivo = `${producto.producto}.jpg`;

            axios.get(url, { responseType: 'blob' })
                .then(response => {
                    const blob = new Blob([response.data], { type: response.data.type });
                    FileSaver.saveAs(blob, nombreArchivo);
                    //console.log("imagen descargada");
                })
                .catch(error => {
                    console.error('Error al descargar la imagen:', error);
                });
        },
        descargarPaquete(producto) {
            //funcion para descargar un paquete de imagenes en todas las dimensiones
            const zip = new JSZip();
            const urls = [
                { url: producto.url, name: `${producto.producto}.jpg` },
                { url: producto.url128, name: `${producto.producto}-128X128.jpg` },
                { url: producto.url256, name: `${producto.producto}-256X256.jpg` },
                { url: producto.url512, name: `${producto.producto}-512X512.jpg` },
                { url: producto.url1024, name: `${producto.producto}-1024X1024.jpg` }
            ];

            const promesas = urls.map(item => {
                if (item.url) {
                    return axios.get(item.url, { responseType: 'blob' })
                        .then(response => zip.file(item.name, response.data))
                        .catch(error => {
                            console.error(`${item.name} no redimensionada`);

                        });
                } else {
                    return Promise.resolve();
                }
            });

            Promise.all(promesas)
                .then(() => {
                    zip.generateAsync({ type: 'blob' })
                        .then(content => {
                            FileSaver.saveAs(content, `${producto.producto}.zip`);
                            //console.log("Paquete descargado correctamente.");
                        });
                })
                .catch(error => {
                    console.error('Error al descargar el paquete:', error);
                });
        },
        mostrarAlertaDetallesProducto(producto) {
            this.$swal({
                title: "Detalles del Producto",
                html: `
            <div class="mb-3">
               <img src="${producto.url}" alt="Imagen del producto" style="max-width: 100px; max-height: 100px;">
            </div>
            <div class="mb-3">
                <label for="inputProducto">${producto.producto}</label>
            </div>
            <div class="mb-3">
                <input type="file" id="fileInput" class="form-control" />
            </div>
            <div class="mb-3 hidden" id="selectedImageLabel">
                <label for="inputimagen">Imagen Seleccionada</label>
            </div>
            <div class="mb-3 hidden" id="previewContainer">
                <img id="previewImage" alt="" style="max-width: 100px; max-height: 100px;">
            </div>
            <div class="mb-3">
                <button id="uploadButton" class="btn btn-outline-primary">Actualizar Imagen</button>
            </div>
        `,
                showCancelButton: false,
                showCloseButton: true,
                showConfirmButton: false,
                didOpen: () => {
                    const fileInput = document.getElementById('fileInput');
                    const previewImage = document.getElementById('previewImage');
                    const selectedImageLabel = document.getElementById('selectedImageLabel');
                    const previewContainer = document.getElementById('previewContainer');
                    const uploadButton = document.getElementById('uploadButton');
                    const uid = this.usuarioActual?.uid;

                    // Listener para mostrar la vista previa y el label cuando se selecciona un archivo
                    fileInput.addEventListener('change', (event) => {
                        const file = event.target.files[0];
                        if (file) {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                previewImage.src = e.target.result;
                            };
                            reader.readAsDataURL(file);


                            selectedImageLabel.classList.remove('hidden');
                            previewContainer.classList.remove('hidden');
                        }
                    });

                    uploadButton.addEventListener('click', () => {
                        this.isLoading = true;
                        this.tituloLoader = "ACTUALIZANDO IMAGEN";
                        this.textoLoader = "Por favor espere se esta actualizando la imagen del producto";
                        const file = fileInput.files[0];

                        if (file) {
                            const fileName = file.name;
                            const fileBaseName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
                            const formattedFileName = fileBaseName.replace(/^0+/, '');
                            const formattedProductName = producto.producto.replace(/^0+/, '');

                            if (formattedFileName === formattedProductName) {
                                this.$swal.close();

                                const newFileName = formattedFileName + fileName.substring(fileName.lastIndexOf('.'));
                                const newFile = new File([file], newFileName, { type: file.type });
                                this.uploadImage(newFile, uid);
                            } else {
                                this.$swal('Error', 'El nombre del archivo debe coincidir con el nombre del producto.', 'error');
                                this.isLoading = false;
                            }
                        } else {
                            this.$swal('Error', 'Por favor selecciona una imagen primero.', 'error');
                            this.isLoading = false;
                        }
                    });
                }
            });
        },
        async uploadImage(file, uid) {
            const formData = new FormData();
            formData.append('files', file);
            formData.append('uid', uid);

            try {
                const response = await axios.post(updateProduct, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                this.$swal('Éxito', 'Imagen actualizada con éxito', 'success').then(() => {
                    this.consultarProductos();
                });

            } catch (error) {
                console.error('ERROR AL SUBIR IMAGENES A FIREBASE', error);
                this.$swal('Error', 'Hubo un problema al actualizar la imagen.', 'error');
            } finally {
                this.isLoading = false;
            }
        },

    },
    mounted() {
        this.consultarProductos();
    }
};
</script>

<style>
.pagination {
    cursor: pointer;
}

.hidden {
    display: none;
}

.desig-but {
    margin: 20px 25px
}

.swal2-confirm {
    color: white !important;
}

.swal2-cancel {
    color: white !important;
}
.v-card{
    overflow: visible !important;
}
</style>
