<template>
  <div class="row">
    <div class="col-md-3 mb-5">
      <div class="card profit-card">
        <div class="card-header pb-0 text-center">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <p class="square-after f-w-600 header-text-primary">Imágenes Ingresados</p>
            </div>
          </div>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <h5 class="square-after f-w-600 header-text-primary text-center">TOTAL</h5>
          <h1 class="font-primary counter">{{ totalProductos }}</h1>
        </div>
      </div>
    </div>

    <div class="col-md-3 mb-5">
      <div class="card profit-card">
        <div class="card-header pb-0 text-center">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <p class="square-after f-w-600 header-text-primary">Imágenes
                Ingresados</p>
            </div>
          </div>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <h5 class="square-after f-w-600 header-text-primary text-center">Últimos 7 días</h5>
          <h1 class="font-primary counter">{{ totalProductosUltimos7Dias }}</h1>
        </div>
      </div>
    </div>

    <div class="col-md-3 mb-5">
      <div class="card profit-card">
        <div class="card-header pb-0 text-center">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <p class="square-after f-w-600 header-text-primary">Banco de
                Imágenes</p>
              <br>
            </div>
          </div>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <router-link to="/banco-imagenes">
            <i class="fas fa-images fa-4x" style="color: #007bff;"></i>
          </router-link>
        </div>
      </div>
    </div>

    <div class="col-md-3 mb-5">
      <div class="card profit-card">
        <div class="card-header pb-0 text-center">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <p class="square-after f-w-600 header-text-primary">Subir
                Imagen</p>
              <br>
              <br>
            </div>
          </div>
        </div>
        <div class="card-body text-center d-flex flex-column justify-content-center">
          <router-link to="/subir-productos">
            <i class="fas fa-cloud-upload-alt fa-4x" style="color: #007bff;"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getProductsDays, getTotalProducts, } from '@/store/apibase';

export default {
  name: 'CardComponent',
  data() {
    return {
      totalProductos: 0,
      totalProductosUltimos7Dias: 0,
    };
  },
  mounted() {
    this.TotalProductos();
    this.TotalProductosUltimos7Dias();
  },
  methods: {
    //Esta funcion nos calcula el total de productos y nos manda la cantidad en la pagina principal
    async TotalProductos() {
      try {
        const response = await axios.get(getTotalProducts);
        this.totalProductos = response.data.total;
        console.log("TOTAL PRODUCTOS: " + response.data.total);
      } catch (error) {
        console.error('ERROR', error);
      }
    },
    //Esta funcion nos calcula el total de productos agregados de los ultimos 7 dias y nos manda la cantidad en la pagina principal
    async TotalProductosUltimos7Dias() {
      try {
        const response = await axios.get(getProductsDays);
        this.totalProductosUltimos7Dias = response.data.total;
        console.log("TOTAL PRODUCTOS 7 DIAS: " + response.data.total);
      } catch (error) {
        console.error('ERROR', error);
      }
    },
  },
};
</script>

<style scoped>
.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.card:hover {
  transform: translateY(-5px);
}

.card-header {
  background-color: #f8f9fa;
}

.fas.fa-images,
.fas.fa-cloud-upload-alt {
  font-size: 4rem;
}

.f-w-600{
  font-size: 20px;
  color: black !important;
}
</style>
