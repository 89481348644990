<template>
  <div class="col-xl-4 col-lg-7 col-md-6 w-100 box-col-38 xl-38">
    <div class="card goal-view">
      <div class="card-header pb-0">
        <div class="d-flex justify-content-between">
          <div class="flex-grow-1">
            <p class="square-after f-w-600">Total Imágenes<i class="fa fa-circle"> </i></p>
            <h4>Productos Ingresados en Super kompras va</h4>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <br>
        <ul>
          <li>
            <span class="f-w-500 header-text-primary">Total Productos</span><h4 class="f-w-700 header-text-primary">{{ totalProductos }}</h4>
          </li>
          <li>
            <router-link :to="{ name: 'TablaReporteFotos', params: { conFoto: 'false' } }">
              <span class="f-w-500">Productos sin Foto en la APP</span><h4 class="f-w-700">{{ productosSinFoto }}</h4>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'TablaReporteFotos', params: { conFoto: 'true' } }">
              <span class="f-w-500">Productos con Foto en la APP</span><h4 class="f-w-700">{{ productosConFoto }}</h4>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="card-body d-flex flex-column align-items-center justify-content-center">
        <div class="goal-chart">
          <div class="shap-block">
            <div class="rounded-shap animate-bg-secondary"><i></i><i></i><i></i></div>
          </div>
          <div id="goal">
            <goalChart :percentage="percentage" />
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import goalChart from "./goalChart.vue";
import { getProductFotoRFCHasura, getlistofProductFotoRFCHasura } from '@/store/apibase';

export default {
  components: {
    goalChart
  },
  data() {
    return {
      totalProductos: 0,
      productosConFoto: 0,
      productosSinFoto: 0,
    };
  },
  mounted() {
    this.obtenerDatos();
  },
  computed: {
    percentage() {
      return ((this.productosConFoto / this.totalProductos) * 100).toFixed(2);
    }
  },
  methods: {
    async obtenerDatos() {
      await this.obtenerListaProductosCSFoto();
      await this.obtenerProductosConFoto();
      await this.obtenerProductosSinFoto();
    },
    async obtenerListaProductosCSFoto() {
      try {
        const response = await axios.post(getlistofProductFotoRFCHasura);
        //en desarrollo
        //this.totalProductos = response.data.productosRFC.dbo_Prod_prov_foto;
        //en produccion
        this.totalProductos = response.data.productosRFC.Prod_prov_foto.length;
      } catch (error) {
        console.error('ERROR', error);
      }
    },
    async obtenerProductosConFoto() {
      try {
        const data = { Foto: 'SI' };
        const response = await axios.post(getProductFotoRFCHasura, data);
        //en desarrollo
        //this.productosConFoto = response.data.productosFoto.dbo_Prod_prov_foto.length;
        //en produccion
        this.productosConFoto = response.data.productosFoto.Prod_prov_foto.length;
      } catch (error) {
        console.error('ERROR', error);
      }
    },

    async obtenerProductosSinFoto() {
      try {
        const data = { Foto: 'NO' };
        const response = await axios.post(getProductFotoRFCHasura, data);
        //en desarrollo
        //this.productosSinFoto = response.data.productosFoto.dbo_Prod_prov_foto.length;
        //en produccion
        this.productosSinFoto = response.data.productosFoto.Prod_prov_foto.length;

      } catch (error) {
        console.error('ERROR', error);
      }
    },
  }
};
</script>

<style scoped>
.goal-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shap-block,
#goal {
  margin: -15px 0;
}
</style>